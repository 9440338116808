.applicationEditWrap {
  width: 100%;
}

.margin {
  margin-bottom: 40px;
}

.tableWrap {
  position: relative;
  width: 100%;
  max-width: calc(100vw - 280px);
  overflow: scroll;
}

.table {
  position: relative;
  width: 1200px;
}

.tableRowTitle {
  width: 230px;
  background-color: rgb(216 224 225 / 100%);
}

.cellShort {
  width: 120px;
  background-color: rgb(216 224 225 / 100%);
}

.tableRowData {
  text-align: left;
  background-color: #fff;
}

.tableCenter {
  text-align: center;
}

.noteTitle {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: top;
}

.note {
  width: 800px;
  padding: 0;
}

.note div {
  height: 100%;
  padding: 0;
}

.note textarea {
  height: 120px !important;
  padding: 15px;
  background-color: #fff;
}

.note div::before {
  border-bottom: none;
}

.note div::after {
  border-bottom: none;
}

.edit div:nth-child(1).selectBox {
  background-color: #fff;
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.edit div:nth-child(1) {
  background-color: rgb(255 255 255 / 0%);
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.leftGroup {
  display: flex;
  gap: 33px;
  align-items: center;
}

.lead,
.responsiblePerson {
  font-size: 16px;
}

.label {
  display: inline-block;
  min-width: 68px;
  padding: 2px 4px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
}

.button {
  width: 168px;
  min-height: 40.5px;
  padding: 8px 0;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: #457670;
}

.tdText {
  font-size: 16px;
  line-height: 1;
}

.notData {
  font-size: 16px;
  font-weight: 400;
  color: #d9d9d9;
  text-align: center;
}

.targetFlex {
  display: flex;
  gap: 10px;
  align-items: center;
}

.detailBox {
  width: 100%;
  height: 200px;
}

.tableButton {
  width: 100%;
  min-height: 40px;
  margin: 12px auto;
  font-size: 16px;
  color: #457670;
  text-align: center;
  background: #fff;
}

.link {
  color: #3076ff;
}

.readonly {
  opacity: 0.5;
}

.comment {
  color: #457670;
}

.kome {
  font-size: 0.8rem;
}

.container {
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}
