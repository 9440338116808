.container {
  height: 466px;
}

.sideButton {
  font-size: 13px;
  color: #3076ff;
}

.icon {
  width: 13px;
  height: 13px;
  color: #000;
}

.btnContainer {
  margin-bottom: 20px;
}

.month {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 15%);
}

.typoMonth {
  font-size: 16px;
  font-weight: 700;
  color: #457670;
  text-align: right;
}

.typoCount {
  font-size: 14px;
  color: #606060;
  text-align: left;
}

.table {
  overflow-y: scroll;
  table-layout: auto;
}

.title {
  background-color: rgb(216 224 225 / 100%);
}

.body {
  max-width: 200px;
  overflow-y: auto;
  background-color: #fff;
}

.year {
  font-size: 16px;
}

.row {
  display: table-row-group;
  width: 320px;
}

.cell {
  display: table-cell;
  width: 160px;
  border: none;
}

.line {
  margin: 0;
  border-bottom: 1px solid #d9d9d9;
}

.activeMonth {
  background-color: #d8e0e1;
}
