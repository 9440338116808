.tableRowTitle {
  width: 206px;
  background-color: rgb(216 224 225 / 100%);
}

.tableRowData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  background-color: #fff;
}

.note {
  width: 800px;
  padding: 0;
}

.note.shared_note {
  width: 100%;
}

.note div {
  height: 100%;
  padding: 0;
}

.note textarea {
  height: 120px !important;
  padding: 15px;
  background-color: #fff;
}

.note div::before {
  border-bottom: none;
}

.note div::after {
  border-bottom: none;
}

.edit div:nth-child(1).selectBox {
  background-color: #fff;
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.edit div:nth-child(1) {
  background-color: rgb(255 255 255 / 0%);
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.button {
  width: 168px;
  min-height: 40.5px;
  padding: 8px 0;
  font-size: 0.875rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: #457670;
  box-shadow: rgb(0 0 0 / 14%) 0 3px 4px;
}

.button:hover {
  color: #fff;
  background-color: #457670;
}

.multiline {
  min-height: 20px;
  white-space: pre-line;
}

.comment {
  color: #457670;
}

.disable {
  color: rgb(96 96 96);
}

.container {
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}

.kome {
  font-size: 0.8rem;
}
