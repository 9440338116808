.modalButtonContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 8px;
}

.infoText {
  font-size: 0.8125rem;
  text-align: left;
}

.preLine {
  white-space: pre-line;
}

.modalContainer {
  height: 400px;
  overflow-y: scroll;
}
