.loginField .MuiInput-root {
  position: relative;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  margin-top: 21px;
}

.loginField .MuiTextField-root {
  display: flex;
  max-width: 356px;
  margin: 0 auto 20px;
}
