.tab {
  &.MuiButtonBase-root.MuiTab-root {
    font-size: 16px;
    color: #457670;
    background: #d9d9d9;
    border-radius: 10px 10px 0 0;
  }

  &.MuiButtonBase-root.MuiTab-root.Mui-selected {
    font-size: 16px;
    font-weight: 700;
    color: #457670;
    background: #fff;
    border-bottom-color: none;
    border-radius: 10px 10px 0 0;
  }
}
