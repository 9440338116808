.list {
  & .MuiTableRow-head .RaDatagrid-headerCell {
    text-align: center;
    background: #d8e0e1;
  }

  & .MuiTableCell-root {
    text-align: center;
  }
}
