.annotation {
  display: flex;
  align-items: center;
  max-width: 356px;
  margin: 0 auto;
  font-size: 13px;
  font-weight: 400;
  color: #606060;
}

.annotationMark {
  display: inline-block;
  margin-right: 3px;
}

.viewIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.modalTitle {
  margin-bottom: 30px;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}

.modalComment {
  margin-bottom: 20px;
  font-size: 1.1rem;
  text-align: center;
}

.modalContentsContainer {
  justify-content: center;
  padding: 15px;
  margin-bottom: 40px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.modalButtonContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
}

.infoText {
  font-size: 0.8125rem;
  text-align: left;
}

.loginField {
  margin-top: 10px;
}

.loginFieldDefault {
  & label {
    color: #606060;
  }

  & fieldset {
    border-color: #606060;
  }
}

.loginFieldError {
  & label {
    color: #ff7171;
  }

  & fieldset {
    border-color: #ff7171;
  }
}

.inputBox {
  width: 100%;
  max-width: 356px;
  margin-bottom: 20px;
}

.label {
  position: relative;
  padding-left: 20px;
  font-weight: 700;
  color: #606060;
  text-align: left;
}

.label::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 14px;
  height: 14px;
  content: '';
  background: #606060;
  border-radius: 100%;
  transform: translate(0, -50%);
}

.error {
  color: #ff7171;
}

.error::before {
  content: '';
  background: #ff7171;
}

.errorMessage {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  color: #ff7171;
}

.errorMessage::before {
  display: block;
  width: 18px;
  height: 18px;
  content: '';
  background: url('../../../../public/images/icon/important-icon.svg')
    center/cover;
}
