.tableRowTitle {
  width: 208px;
  background-color: rgb(216 224 225 / 100%);
}

.tableRowData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  background-color: #fff;
}

.note {
  width: 800px;
  min-height: 120px;
  padding: 15px;
  text-align: left;
  white-space: pre-line;
  background-color: rgb(255 255 255 / 100%);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.show div:nth-child(1) {
  background-color: rgb(255 255 255 / 0%);
  box-shadow: none;
  transition: none;
}

.show div:nth-child(1).statusText {
  box-shadow: none;
  transition: none;
}

.comment {
  color: #457670;
}

.button {
  width: 168px;
  min-height: 40.5px;
  padding: 8px 0;
  font-size: 0.875rem;
  font-weight: 700;
  color: #fff;
  background-color: #457670;
  box-shadow: rgb(0 0 0 / 14%) 0 3px 4px;
}

.button:hover {
  color: #fff;
  background-color: #457670;
}

.icon {
  width: 13px;
  color: #457670;
}

.modalContainer {
  max-height: 470px;
}

.kome {
  font-size: 0.8rem;
}

.newLine {
  white-space: pre-line;
}
