.button {
  width: 168px;
  min-height: 40.5px;
  padding: 8px 0;
  font-weight: 700;
  text-align: center;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
