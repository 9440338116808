.button {
  width: 168px;
  padding: 8px 0;
  font-weight: 700;
  text-align: center;
  box-shadow: rgb(0 0 0 / 14%) 0 3px 4px;
}

.white {
  color: #457670;
  background-color: #fff;
}

.showButton {
  color: #3076ff;
}

.currentPage {
  font-weight: 700;
}

.statusText {
  font-weight: 700;
}

.valid {
  color: #457670;
}

.invalid {
  color: #ff7171;
}

.address {
  overflow: hidden;
}

.pagination {
  justify-content: center;
}
