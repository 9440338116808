#root .MuiScopedCssBaseline-root * {
  font-family: Inter, sans-serif;
}

.RaList-content {
  display: grid;
  background-color: transparent;
  background-color: #f5f5f5;
  box-shadow: none;
  transition: none;
}

.RaDatagrid-root {
  width: 100%;
  max-height: calc(100vh - 48px - 180px - 52px);
  margin-top: 20px;
  overflow: auto;
  white-space: nowrap;
  background-color: #fff;
}

#main-content {
  padding: 30px;
}

.RaLayout-appFrame {
  background-color: #d9d9d9;
}

.MuiFormControl-root {
  background-color: #fff;
}

.MuiButtonBase-root.MuiMenuItem-root {
  color: #000;

  & .MuiListItemIcon-root.RaMenuItemLink-icon {
    color: #000;
  }
}

.MuiButtonBase-root.MuiMenuItem-root.RaMenuItemLink-active {
  font-weight: 700;
  color: #457670;
  background-color: #f5f5f5;
  border-radius: 5px;

  & .MuiListItemIcon-root.RaMenuItemLink-icon {
    color: #457670;
  }
}

.MuiList-root.RaMenu-open {
  padding-right: 12px;
  padding-left: 12px;
}

.MuiList-root.RaMenu-closed {
  padding-right: 0;
  padding-left: 0;

  & .RaMenuItemLink-active {
    border-radius: 0;
  }
}

.RaEdit-main.RaEdit-noActions {
  margin-top: 0;
}

li[aria-label='空にする'].MuiButtonBase-root.MuiMenuItem-root {
  display: none;
}

li[aria-label='空にする'][data-value='null'].MuiButtonBase-root.MuiMenuItem-root {
  display: block;
}
