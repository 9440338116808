.tableRowTitle {
  width: 200px;
  background-color: rgb(216 224 225 / 100%);
}

.tableRowData {
  text-align: left;
  background-color: #fff;
}

.note {
  width: 800px;
  min-height: 120px;
  padding: 15px;
  text-align: left;
  white-space: pre-line;
  background-color: rgb(255 255 255 / 100%);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.show div:nth-child(1) {
  background-color: rgb(255 255 255 / 0%);
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.button {
  width: 170px;
  font-size: 0.875rem;
  font-weight: 700;
}

.button:hover {
  color: #fff;
  background-color: #457670;
}
