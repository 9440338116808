.tableRowTitle {
  width: 200px;
  background-color: rgb(216 224 225 / 100%);
}

.tableRowData {
  background-color: #fff;
}

.disabled {
  opacity: 0.5;
}

.selectBox {
  height: fit-content;
  padding: 0;
  margin: 0;
}

.selectInput {
  background-color: #fff;
}

.note {
  width: 800px;
  padding: 0;
}

.note div {
  height: 100%;
  padding: 0;
}

.note textarea {
  height: 120px !important;
  padding: 15px;
  background-color: #fff;
}

.note div::before {
  border-bottom: none;
}

.note div::after {
  border-bottom: none;
}

.edit div:nth-child(1) {
  background-color: rgb(255 255 255 / 0%);
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.button {
  width: 168px;
  min-height: 40.5px;
  padding: 8px 0;
  font-size: 0.875rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: #457670;
  box-shadow: rgb(0 0 0 / 14%) 0 3px 4px;
}

.button:hover {
  color: #fff;
  background-color: #457670;
}

.container {
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}
