.tableRowTitle {
  width: 200px;
  background-color: rgb(216 224 225 / 100%);
}

.tableRowData {
  text-align: left;
  background-color: #fff;
}

.lead {
  font-size: 16px;
}

.label {
  display: inline-block;
  min-width: 68px;
  padding: 2px 4px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
}

.btnWrap {
  text-align: center;
}

.submit {
  min-width: 200px;
  color: #fff;
  background: #457670;
  border-radius: 5px;
}

.listContent {
  list-style: none;
}

.sendText {
  font-weight: 700;
  text-align: center;
}

.completeBtn {
  margin-top: 20px;
  text-align: center;
}

.completeWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.note {
  width: 100%;
  padding: 0;
}

.note div {
  height: 100%;
  padding: 0;
}

.note textarea {
  height: 120px !important;
  padding: 15px;
  background-color: #fff;
}

.note div::before {
  border-bottom: none;
}

.note div::after {
  border-bottom: none;
}

.create div:nth-child(1).selectBox {
  background-color: #fff;
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.create div:nth-child(1) {
  background-color: rgb(255 255 255 / 0%);
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.container {
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.button {
  width: 168px;
  min-height: 40.5px;
  padding: 8px 0;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: #457670;
}
