.modalTitle {
  margin-bottom: 30px;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}

.modalComment {
  margin-bottom: 35px;
  font-size: 1.1rem;
  text-align: center;
}

.modalContentsContainer {
  justify-content: center;
  padding: 15px;
  margin-bottom: 40px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.modalButtonContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 8px;
}

.infoText {
  font-size: 0.8125rem;
  text-align: left;
}
