.loginWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.hdgText {
  margin-bottom: 40px;
  font-size: 40px;
  font-weight: 700;
  color: #000;
  text-align: center;
}

.line {
  height: 1px;
  background-color: #d9d9d9;
  border: none;
}

.userText {
  font-size: 22px;
  font-weight: 700;
  color: #000;
  text-align: center;
}

.viewIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.form {
  margin: 0;
  text-align: center;
}

.inputBox {
  width: 100%;
  max-width: 356px;
}

.loginField {
  margin-top: 15px;
}

.loginFieldDefault {
  background: #fff;
}

.btn {
  max-width: 356px;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background: #000;
  transition: 0.8s;
}

.btn:hover,
.btn:focus {
  background-color: rgb(0 0 0 / 50%);
  transition: 0.3s;
}

.questionText {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: #606060;
}

.questionIcon {
  display: block;
  width: 18px;
  height: 18px;
}

.container {
  display: flex;
  flex-flow: column;
  gap: 30px;
  align-items: center;
  margin: 40px auto;
}

.loginBox {
  display: flex;
  flex-flow: column;
  gap: 20px;
  align-items: center;
}

.errorMessage {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #ff7171;
}

.errorMessage::before {
  display: block;
  width: 18px;
  height: 18px;
  content: '';
  background: url('../../../../public/images/icon/important-icon.svg')
    center/cover;
}

.loginFieldError {
  & label {
    color: #ff7171;
  }

  & fieldset {
    border-color: #ff7171;
  }
}

.label {
  position: relative;
  padding-left: 20px;
  font-weight: 700;
  color: #606060;
  text-align: left;
}

.label::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 14px;
  height: 14px;
  content: '';
  background: #606060;
  border-radius: 100%;
  transform: translate(0, -50%);
}

.error {
  color: #ff7171;
}

.error::before {
  content: '';
  background: #ff7171;
}
