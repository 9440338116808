.contentsTitle {
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;
  font-weight: 700;
  color: #457670;
}

.contentsTitle::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 14px;
  height: 14px;
  content: '';
  background: #457670;
  border-radius: 100%;
  transform: translate(0, -50%);
}
