.button {
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  font-size: 13px;
  font-weight: 700;
  color: #457670;
  cursor: pointer;
  background: #fff;
  filter: drop-shadow(0 4px 4px rgb(0 0 0 / 25%));
  border: none;
  border-radius: 50%;
}
