.unSupported {
  color: #d9d9d9;
}

.inSupported {
  color: #ff8a48;
}

.inStallation,
.complete {
  color: #457670;
}

.inValid,
.cancel {
  color: #606060;
}

.status {
  font-weight: 700;
}

.applicationListWrap {
  width: 100%;
  max-width: calc(100vw - 40px);
}

.tableCenter {
  display: block;
  width: 100%;
  text-align: center;
}

.showButton {
  color: #3076ff;
}
