.loaders {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.loaders .loader {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 100px;
}

@keyframes ball-spin-fade-loader {
  50% {
    opacity: 0.3;
    transform: scale(0.4);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ball-spin-fade-loader {
  position: relative;
  top: -10px;
  left: -10px;
}

.ball-spin-fade-loader > div {
  position: absolute;
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #457670;
  border-radius: 100%;
  animation-fill-mode: both;
}

.ball-spin-fade-loader > div:nth-child(1) {
  top: 25px;
  left: 0;
  animation: ball-spin-fade-loader 1.5s -0.46s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(2) {
  top: 17.0454px;
  left: 17.0454px;
  animation: ball-spin-fade-loader 1.5s -0.34s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(3) {
  top: 0;
  left: 25px;
  animation: ball-spin-fade-loader 1.5s -0.22s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(4) {
  top: -17.0454px;
  left: 17.0454px;
  animation: ball-spin-fade-loader 1.5s 0.02s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(5) {
  top: -25px;
  left: 0;
  animation: ball-spin-fade-loader 1.5s 0.02s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(6) {
  top: -17.0454px;
  left: -17.0454px;
  animation: ball-spin-fade-loader 1.5s 0.14s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(7) {
  top: 0;
  left: -25px;
  animation: ball-spin-fade-loader 1.5s 0.26s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(8) {
  top: 17.0454px;
  left: -17.0454px;
  animation: ball-spin-fade-loader 1.5s 0.38s infinite linear;
}
