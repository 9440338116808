.title {
  background-color: rgb(216 224 225 / 100%);
}

.body {
  background-color: #fff;
}

/* .table {
  max-width: 800px;
} */

.year {
  font-size: 16px;
}

.sideButton {
  font-size: 13px;
  color: #3076ff;
}

.icon {
  width: 13px;
  height: 13px;
  color: #000;
}

.container {
  margin-bottom: 20px;
}
