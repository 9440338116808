.margin {
  margin-bottom: 40px;
}

.tableWrap {
  position: relative;
  width: 100%;
  max-width: 920px;
  overflow: scroll;
}

.table {
  position: relative;
  width: 1340px;
}

.tdText {
  font-size: 0.875rem;
  line-height: 1;
}

.tableRowTitle {
  width: 206px;
  background-color: rgb(216 224 225 / 100%);
}

.tableRowData {
  min-height: 56px;
  text-align: left;
  background-color: #fff;
}

.tableCenter {
  text-align: center;
}

.note {
  width: 800px;
  min-height: 120px;
  padding: 15px;
  text-align: left;
  white-space: pre-line;
  background-color: rgb(255 255 255 / 100%);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.show div:nth-child(1) {
  background-color: rgb(255 255 255 / 0%);
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.leftGroup {
  display: flex;
  gap: 33px;
  align-items: center;
}

.lead,
.responsiblePerson {
  font-size: 16px;
}

.label {
  display: inline-block;
  min-width: 68px;
  padding: 2px 4px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
}

.unSupported {
  background: #d9d9d9;
}

.inSupported {
  color: #fff;
  background: #ff8a48;
}

.inStallation,
.complete {
  background: #457670;
}

.inValid,
.cancel {
  background: #606060;
}

.notData {
  font-size: 16px;
  font-weight: 400;
  color: #d9d9d9;
  text-align: center;
}

.button {
  width: 168px;
  min-height: 40.5px;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: #457670;
  box-shadow: rgb(0 0 0 / 14%) 0 3px 4px;
}

.link {
  color: #3076ff;
}

.interviewRowTitle {
  padding: 8;
  background-color: rgb(216 224 225 / 100%);
}

.noteTitle {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: top;
}

.comment {
  color: #457670;
}

.kome {
  font-size: 0.8rem;
}

.newLine {
  white-space: pre-line;
}
