.tooltip {
  color: #606060;
  background-color: #e3e3e3;
}

.arrow::before {
  background-color: #e3e3e3;
}

.note div {
  padding: 10px;
  text-align: left;
  background-color: rgb(255 255 255 / 100%);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.note div textarea {
  min-height: 235px;
}

.note div::before {
  border-bottom: none;
}

.note div::after {
  border-bottom: none;
}

.modalButtonContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}

.infoText {
  font-size: 0.8125rem;
  text-align: left;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  text-align: center;
}

.modalContainer {
  padding: 30px;
}

.comment {
  padding: 10px;
  text-align: center;
}
