.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.btnWrapper {
  margin-top: 20px;
}

.inputBox {
  width: 100%;
  max-width: 356px;
}

.loginField {
  margin-top: 10px;
}

.loginFieldDefault {
  & label {
    color: #606060;
  }

  & fieldset {
    border-color: #606060;
  }
}

.loginFieldError {
  & label {
    color: #ff7171;
  }

  & fieldset {
    border-color: #ff7171;
  }
}

.label {
  position: relative;
  padding-left: 20px;
  font-weight: 700;
  color: #606060;
  text-align: left;
}

.label::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 14px;
  height: 14px;
  content: '';
  background: #606060;
  border-radius: 100%;
  transform: translate(0, -50%);
}

.error {
  color: #ff7171;
}

.error::before {
  content: '';
  background: #ff7171;
}

.errorMessage {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  color: #ff7171;
}

.errorMessage::before {
  display: block;
  width: 18px;
  height: 18px;
  content: '';
  background: url('../../../../../public/images/icon/important-icon.svg')
    center/cover;
}

.button {
  width: 168px;
  min-height: 40.5px;
  padding: 8px 0;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: #457670;
}

.button:hover {
  color: #fff;
  background-color: #457670;
}
