.modalBox {
  padding: 60px 0;
  margin: 0 auto;
}

.body {
  margin: 0 auto;
}

.title {
  margin-bottom: 30px;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}
